import React, { useState } from "react";
import Layout from "../components/Layout";
import { Box, Typography } from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
// import theme from "../style/theme";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

function ContactForm({ subsite }) {
  const { t } = useTranslation();

  // const classes = useStyles();
  const [state, setState] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: ""
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  function encode(object) {
    return Object.entries(object)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...state
        })
      });
      setSuccess(true);
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  if (success) {
    return (
      <div>
        <p>{t("contactSuccessMessage", { name: state.name })}</p>
      </div>
    );
  }
  return (
    <div>
      <p>{t("contactIntroMessage")}</p>
      {error && <p style={{ color: "red" }}>{t("contactErrorMessage")}</p>}
      <form
        name="contact"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="country" value={subsite.country} />
        <input type="hidden" name="language" value={subsite.lang} />
        <div>
          <div>
            <TextField
              required
              label={t("fullName")}
              style={{ margin: 8 }}
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              name="name"
              value={state.name}
              onChange={handleChange}
            />
            <TextField
              required
              label={t("email")}
              style={{ margin: 8 }}
              placeholder={t("emailPlaceholder")}
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              name="email"
              value={state.email}
              onChange={handleChange}
            />
            <TextField
              label={t("phoneNumber")}
              style={{ margin: 8 }}
              placeholder="+86"
              helperText={t("phoneNumberHelperText")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleChange}
            />
            <TextField
              label={t("wechat")}
              style={{ margin: 8 }}
              placeholder={t("wechatPlaceholder")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              name="wechatId"
              value={state.phoneNumber}
              onChange={handleChange}
            />
            <TextField
              multiline
              rows="4"
              label={t("message")}
              style={{ margin: 8 }}
              placeholder={t("messagePlaceholder")}
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              name="message"
              value={state.message}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button variant="contained" color="primary" type="submit">
          {t("send")}
        </Button>
      </form>
    </div>
  );
}

// todo: put pageContext on react context?
/*
export default function ContactFormPage({ pageContext: { subsite } }) {
  return (
    <Layout subsite={subsite}>
      <p>test...</p>;
    </Layout>
  );
}
*/

export default function ContactFormPage({ pageContext: { subsite } }) {
  const { t } = useTranslation();
  return (
    <Layout subsite={subsite}>
      <Box flexGrow={1} width="100%" maxWidth={960} marginX="auto">
        <Box padding={2}>
          <Box marginBottom={1}>
            <Typography
              variant="h4"
              style={{
                fontFamily:
                  "Work Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif"
              }}
            >
              {t("contactFormTitle", { country: t(`${subsite.country}Adj`) })}
            </Typography>
            <ContactForm subsite={subsite} />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
